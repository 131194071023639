<template lang="pug">
div
  .header-tiber-bg
  div
    nova-navbar.tiber-bg(style="height:75px;")
      template(v-slot:middle)
        span.text-white.font-lg.d-none.d-sm-inline Calculating Premium
      template(v-slot:right)
        n-button(
          :disabled="false",
          @click="openCalendlyPopup()",
          :buttonText="'Schedule a call'",
          :variant="'warning'",
          :imageIcon="'calendar'")
    .container-pec(style="padding-top: 1px;")
      .user-progress.d-none.d-lg-block
        .m-auto
          ProgressBar(:steps="progressBarSteps")
      div
        b-card.main-content
          router-view(:prospectState="prospectStateChanges", @saveChanges="saveChanges")
      .row.pt-4.mx-4.mx-lg-0(v-if="$route.path.includes('pricing-table')")
        n-card.col-12.col-lg-6.p-2.pl-lg-0(
          v-if="testimonial",
          cardType="testimonial",
          variant="testimonial",
          :testimonialSource="testimonial.name",
          :testimonialOrg="testimonial.org",
          :cardText="testimonial.text")
        n-card.col-12.col-lg-6.p-2.pr-lg-0.schedule-card(
          :cardTitle="'Need hands-on help or info on policy coverage and premium?'",
          :cardType="'schedule'",
          :imageIcon="'schedule-woman.svg'",
          :cardText="'We are happy to help.'",
          @buttonClick="openCalendlyPopup()")
  n-footer(v-if="$route.path.includes('pricing-table')")
</template>

<script>
import NovaNavbar from "@/layout/NovaNavbar.vue";
import NFooter from "@/layout/Footer.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import NCard from "./components/NovaCard.vue";
import resDefs from "./definitions";
import ProgressBar from "./components/ProgressBar.vue";
export default {
  components: {
    NovaNavbar,
    NButton,
    NCard,
    ProgressBar,
    NFooter,
  },
  data() {
    const resName = "prospects";
    const resDef = resDefs[resName];
    return {
      resName,
      resDef,
      resDefs,
      prospectState: {},
      prospectStateChanges: null,
      prospectId: "",
      altNav: 0, // 3 states, 0 from top to when names get covered, 1 till end of tiber bg, 2 till end of page scroll
      windowTop: 0,
      progressBarSteps: [],
      testimonial: null,
    };
  },
  watch: {
    prospectState() {
      this.prospectStateChanges = JSON.parse(JSON.stringify(this.prospectState));
    },
  },
  async created() {
    this.prospectId = await utils.fromNanoId(this, this.$route.params.id);
    this.progressBarSteps = [
      {
        name: "About Workplace",
        icon: "city",
        routeName: "workspace",
        idParam: this.$route.params.id,
      },
      {
        name: "About Employees",
        icon: "users",
        routeName: "employees",
        idParam: this.$route.params.id,
      },
      {
        name: "Viewing Plans",
        icon: "claims-1",
        routeName: "pricingTable",
        idParam: this.$route.params.id,
      },
      {
        name: "Policy Add-ons",
        icon: "add",
        routeName: "addons",
        idParam: this.$route.params.id,
      },
      {
        name: "Review Plan",
        icon: "flag",
        routeName: "reviewPlan",
        idParam: this.$route.params.id,
      },
    ];
    this.$apollo.addSmartQuery("prospectState", {
      query: this.resDef.singleQuery,
      variables: () => ({
        id: this.prospectId,
      }),
      update(data) {
        if (!data.node) {
          this.$router.push("/error/not-found");
          this.$Progress.fail();
        }
        this.$Progress.finish();
        return this.resDef.transform(data.node);
      },
    });
  },
  async mounted() {
    const result = await this.$apollo.query({
      query: resDefs.strapiContents.listQuery,
      variables: {
        filter: {
          collectionType: "Testimonial",
          tags: ["PricingTable"],
        },
      },
    });
    this.testimonial = result?.data?.strapiContents?.edges.map((t) => t.node?.meta)?.[0];
  },
  methods: {
    openCalendlyPopup() {
      utils.openCalendlyPopup();
    },
    async saveChanges(calculatePremiums) {
      this.prospectState = JSON.parse(JSON.stringify(this.prospectStateChanges));
      this.prospectState.pricingTableState = this.prospectState.pricing_table_state;
      this.prospectState.meta.org_name = this.prospectState.organization;
      this.prospectState.meta.coverage_type = this.prospectState.coverageType;
      this.prospectState.meta.employee_count = this.prospectState.employeeCount;
      this.prospectState.meta.employee_avg_age = this.prospectState.employeeAvgAge;
      this.prospectState.meta.location = this.prospectState.location;
      this.prospectState.meta.industry = this.prospectState.industry;
      this.prospectState.meta.phone = this.prospectState.phone;
      this.prospectState.calculatePremiums = calculatePremiums;
      this.prospectState.meta.emailId = this.prospectState.email;

      this.$store.commit("clearToasts");
      try {
        await this.$apollo.mutate({
          mutation: this.resDef.upsertMutation,
          variables: this.prospectState,
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.tiber-bg {
  background: $tiber;
  max-width: 100vw !important;
}
@media (min-width: 992px) {
  .header-tiber-bg {
    background: linear-gradient(180deg, $tiber 50vh, transparent 50vh);
    max-width: 100vw !important;
    min-height: 100vh !important;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
}
@media (max-width: 768px) {
  .schedule-card {
    height: 30rem;
  }
  .nova-pec-card .schedule-img {
    margin-right: 0px;
    height: 14rem;
    width: 18rem;
    top: 3rem;
    left: calc(33vw - 6rem);
  }
}
@media (max-width: 460px) {
  .nova-pec-card .schedule-img {
    margin-right: 0px;
    height: 14rem;
    width: 18rem;
    top: 1.33rem;
    left: calc(5vw - 1rem);
  }
}
.user-progress {
  position: relative;
  height: 2.75rem;
  background: transparent;
  margin: 20px 0;
  text-align: center;
}

.main-content > .card-body {
  padding: 0;
}

.pec-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 10rem;
  border-bottom-left-radius: 0.5rem;
}

.left-section {
  position: relative;
  min-height: 35rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  color: $gray-700;
  padding: 30px;
  font-size: 0.875rem;
  width: 25rem;
  background: rgba(242, 246, 255, 0.25);
}

.input-card {
  height: calc(100vh - 4.75rem);
  border-radius: 0;
  background-color: white;
}
@media (min-width: 992px) {
  .input-card {
    height: calc(100vh - 10rem);
    background-color: transparent;
  }
}
</style>
