<template lang="pug">
  b-modal.policy-customization-modal(
    :id="id",
    size="xl",
    :content-class="'pc-modal-content'",
    body-class="h-100 p-0",
    @hidden="setPlan(policyData)",
    centered,
    hide-header,
    hide-footer)
    .d-flex.h-100.overflow-hidden(v-if="policy")
      .d-inline-block.col-7.col-xl.px-0.pt-4.h-100(style="overflow: visible;")
        .modal-wrapper
          h5.hb5 Customizing
            span.plan-title {{ this.policy.cardTitle }}
        hr.mb-0
        .modal-fields
          .modal-wrapper
            b-form.py-4()
              vue-form-generator.modal-form(
                :schema="resDef.schema",
                :model="editableDataChanges",
                :options="resOptions",
                ref="formData",
                @model-updated="onModelUpdate")

      .d-inline-block.right-section.p-0
        .modal-accented-section(:class="`bg-${policy.variant}`")
          span.accent-icon(:class="`icon-nova-${policy.termPrice.split('-')[0]}`")
          .font-lg.text-white
            span.h3 {{ termPrice | inr }}
            span / Employee
          .font-sm.text-gray-400.pt-1
            span.h5 Anually

        .modal-summary-section.font-weight-medium.font-sm.text-dark

          .row.no-gutters.pb-1
            .policy-icon(:class="`icon-nova-${policy.termPrice.split('-')[0]} text-${policy.variant}`")
            div Customizing&nbsp;
              span.plan-title {{ this.policy.cardTitle }}
            .col
              hr.m-2
            div {{ basePrice*1.18/this.policyDataChanges.meta.employee_count | inr }}

          div(v-for="price in Object.keys(priceBreakdown)", :key="price")
            .row.no-gutters.py-2(v-if="priceBreakdown[price]")
              div {{ getFieldName(price) }}
              .col
                hr.m-2
              div {{ priceBreakdown[price]*1.18/policyDataChanges.meta.employee_count | inr }}

          hr.my-2.w-100(:class="`bg-${policy.variant}`", style="height: 2px")

          .row.no-gutters.pt-2
            div Lives to be covered
            .col
              hr.m-2
            div x{{ this.policyDataChanges.meta.employee_count }}

          .row.no-gutters.pt-3
            .font-weight-semibold.font-md Total Annual Premium
            .col
              hr.m-2
            .font-weight-semibold.font-md(:class="`text-${policy.variant}`")
              | {{ this.termPrice*this.policyDataChanges.meta.employee_count | inr }}

        .row.modal-button-group.w-100
          .col-12.py-1
            n-button(
              v-if="parent==='pricing-table'",
              block,
              variant="dark",
              buttonText="Save and Continue with this plan",
              @click="updateFormAndPickPlan",
              @keydown.enter="updateFormAndPickPlan")
          .col-6.py-1.pr-1
            n-button(
              :variant="`outline-${policy.variant}`",
              block,
              buttonText="Reset and close",
              @click="closeModal")
          .col-6.py-1.pl-1
            n-button(
              :variant="`${policy.variant}`",
              block,
              buttonText="Save and close",
              @click="updateForm",
              @keydown.enter="updateForm")
    div(v-else)
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import resDefs from "../definitions";
import { isVfgErrorPresent } from "@/utils";

export default {
  components: {
    NButton,
  },
  props: {
    id: {
      type: String,
      default: "customization-modal",
    },
    policyData: {
      type: Object,
      default: () => {},
    },
    planIndex: {
      type: Number,
      default: -1,
    },
    editableData: {
      type: Array,
      default: () => [],
    },
    parent: {
      type: String,
      default: "pricing-table",
    },
  },
  data() {
    const resName = "customizePolicy";
    return {
      policy: "",
      termPrice: 0,
      resName,
      resDef: resDefs[resName],
      policyDataChanges: {},
      resOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      editableDataChanges: {},
      prevEditableDataChanges: {},
      priceBreakdown: {},
      basePrice: 0,
    };
  },
  watch: {
    async planIndex() {
      this.setPlan(this.policyData);
      await this.getPremium(this.editableDataChanges);
      this.initialPriceBreakdown = JSON.parse(JSON.stringify(this.priceBreakdown));
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId === "customization-modal") {
      }
    });
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (modalId === "customization-modal" && this.initialPriceBreakdown) {
        this.priceBreakdown = JSON.parse(JSON.stringify(this.initialPriceBreakdown));
      }
    });
  },
  methods: {
    setPlan(policyData) {
      this.policyDataChanges = JSON.parse(JSON.stringify(policyData));
      this.policy = this.policyDataChanges.pricing_table_state.policies[this.planIndex];
      this.termPrice =
        (parseInt(
          this.policyDataChanges.pricing_table_state.items[this.policyDataChanges.pricing_table_state.items.length - 3][
            this.policy.termPrice
          ]
        ) *
          1.18) /
        (this.policyDataChanges.meta.employee_count || 1);
      this.editableData.forEach((field) => {
        this.editableDataChanges[field.feature.replace(/\s/g, "").toLowerCase()] = field[this.policy.termPrice];
      });
      this.prevEditableDataChanges = JSON.parse(JSON.stringify(this.editableDataChanges));
    },
    updateForm() {
      this.$refs.formData.validate();
      if (isVfgErrorPresent(this.$refs.formData.errors)) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please enter valid details before continuing to the next step",
        });
      } else {
        this.initialPriceBreakdown = JSON.parse(JSON.stringify(this.priceBreakdown));
        this.$emit("saveChangesToTable", {
          policy: this.policy,
          editableDataChanges: {
            ...this.editableDataChanges,
            quote: this.termPrice / 1.18,
            gst: this.termPrice - this.termPrice / 1.18,
          },
        });
        this.closeModal();
      }
    },
    closeModal() {
      this.$bvModal.hide("customization-modal");
    },
    getFieldName(fieldCode) {
      const fieldObject = this.resDef.schema.fields.filter((field) => {
        return field.model === fieldCode;
      })[0];
      return fieldObject.fieldLabel;
    },
    updateFormAndPickPlan() {
      this.updateForm();
      this.$emit("pickPlan", {
        id: this.policy.id,
        name: this.policy.termPrice,
      });
    },
    onModelUpdate() {
      const prevEditableDataChangesKeys = Object.keys(this.prevEditableDataChanges);
      for (const prop of Object.keys(this.editableDataChanges)) {
        if (
          prevEditableDataChangesKeys.includes(prop) &&
          this.prevEditableDataChanges[prop] === this.editableDataChanges[prop]
        ) {
          continue;
        }
        this.getPremium(this.editableDataChanges);
        this.prevEditableDataChanges = JSON.parse(JSON.stringify(this.editableDataChanges));
        break;
      }
    },

    async getPremium(modifiedParameter) {
      try {
        await this.$apollo.mutate({
          mutation: resDefs.prospects.eventMutation,
          variables: {
            id: null,
            prospectId: this.policyData.id,
            eventName: "calculate_premium",
            eventParams: {
              modifiedParameter,
              policyName: this.policy.termPrice,
            },
          },
          update: (store, { data }) => {
            const params = data.upsertProspectEvent.prospectEvent.event_params;
            this.termPrice = (params.changedPrice * 1.18) / (this.policyDataChanges.meta.employee_count || 1);
            this.priceBreakdown = params.partialPrices;
            this.basePrice = params.initialPremium;
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.pc-modal-content {
  border-width: 0 !important;
  border-top-left-radius: 0.35rem !important;
  border-top-right-radius: 0.35rem !important;
  height: 90vh !important;
}

.plan-title {
  text-transform: capitalize;
}
.modal-summary-section {
  padding: 1.5rem 2rem;
}
.right-section {
  position: relative;
  height: 100;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  color: $gray-700;
  padding: 30px;
  font-size: 0.875rem;
  width: 25rem;
  background: rgba(242, 246, 255, 0.25);
  border-top-right-radius: 0.3rem;
}

.modal-wrapper {
  width: 20rem;
  margin: 0 auto;
}
@media (min-width: 1200px) {
  .modal-wrapper {
    width: 40rem;
    margin: 0 auto;
  }
}
.pc-modal-content > div > div > div > .modal-fields {
  height: 94.2%;
  overflow-y: auto;
}
@media (min-width: 1500px) {
  .modal-xl {
    max-width: 1400px;
  }
}
.policy-icon {
  padding-right: 0.25rem;
  position: relative;
  top: -0.2rem;
}
.modal-accented-section {
  height: 18%;
  border-top-right-radius: 0.3rem;
  padding: 2rem;
}
.accent-icon {
  font-size: 15.5vh;
  position: absolute;
  right: -2.25rem;
  top: 0;

  &.icon-nova-basic {
    color: $blue-700;
  }
  &.icon-nova-standard {
    color: $gray-700;
  }
  &.icon-nova-prime {
    color: $teal-900;
  }
}
.modal-button-group {
  position: absolute;
  margin: 0 0 1rem 0;
  bottom: 0;
}
hr {
  border: 0.5px solid $gray-300;
  background: $gray-300;
}
.vue-form-generator.modal-form > fieldset > .field-select-cards:not(:last-child) {
  padding-bottom: 2rem;
  border-bottom: 1px solid $gray-300;
}
</style>
