<template lang="pug">
div
  .header-tiber-bg
  div(v-if="prospectState.pricing_table_state")
    nova-navbar.tiber-bg(style="height:75px;")
      template(v-slot:middle)
        span.text-white.font-lg.d-none.d-sm-inline Calculating Premium
      template(v-slot:right)
        n-button(
          @click="openCalendlyPopup",
          :buttonText="'Schedule a call'",
          :variant="'warning'",
          :imageIcon="'calendar'")

    .container-pec(style="padding-top: 1px;")
      .user-progress.d-none.d-lg-block
        .m-auto
          ProgressBar(:steps="progressBarSteps")
      .row.no-gutters
        .col-lg-7.col-md-12
          .review-container
            b-card.main-content.bg-card
              .container.p-0
                .p-0.review-left-section.website-bg
                  .review-plan-header.rounded
                    span.hb4.header-text.font-weight-semibold.p-4.ml-2.d-flex Your Plan
                  .plan-info-row.px-2
                    .border-rad.row.mx-0(:class="'bg-'+getPlanClass")
                      .col-sm-8.pl-4
                        .mt-4
                          i.mr-1.position-absolute(:class="`icon-nova-${prospectState.meta.selectedPolicy}`")
                          span.ml-4.hb5.font-weight-semibold Nova {{capitalizePlanName}}
                        .row.no-gutters.my-3
                          .col-lg-6
                            .font-sm.font-weight-medium.pr-4.pb-2
                              | Cost-efficient plan to provide basic coverage for your team
                          .col-lg-6
                            span.font-weight-semibold.font-lg
                              | {{ getPremiumEstimate[0]/prospectState.meta.employee_count | inr }}
                            span / Employee
                            .font-sm.sub-amount-text Annually
                      .col-sm-4
                        n-button.d-none.d-lg-block.my-2.mt-3(
                          block,
                          @click="showCustomizationModal()",
                          buttonText="Customize Plan",
                          variant="light")
                        n-button.mt-sm-3.mt-lg-2.my-2(
                          :class="`btn-${prospectState.meta.selectedPolicy}-plan`",
                          block,
                          :disabled="false",
                          @click="goToPoliciesPage",
                          buttonText="Switch Plan",
                          variant="primary")

                  .params-title(:class="`border-nova-${prospectState.meta.selectedPolicy}`")
                    .d-none.d-lg-block.font-hc.font-weight-semibold Key Parameters
                    .row.pt-0.no-gutters
                      .col-md-12.table-container(:class="expandTableInfo ? 'expandTable': 'collapseTable'")
                        b-table.plan-table(
                          fixed,
                          :items="prospectState.pricing_table_state.items",
                          :fields="selectedPlanFields",
                          :no-border-collapse="true",
                          thead-class="d-none")
                          template(#cell(feature)="scope")
                            span.col-md-10.px-0.d-inline-block.align-middle.font-weight-normal.font-weight-light
                              | {{ scope.item.feature }}
                          template(#cell()="scope")
                            span.icon-check-circle(v-if="scope.value.toString().trim()=='yes'")
                            span.icon-cancel.text-gray-500(v-else-if="scope.value.toString().trim()=='no'")
                            span.font-weight-medium(
                              v-else-if="scope.value.toString().charAt(0)==='!'") {{ scope.value.substring(1) | inr }}
                            span.font-weight-medium(v-else) {{ scope.value | inr }}
                      n-button.ml-2.d-block.d-lg-none.white-btn.mb-4(
                        @click="expandTable",
                        :buttonText="expandTableInfo ?'Hide details' : 'View Details'",
                        :variant="'light'",
                        :rightImageIcon="expandTableInfo ? 'chevron-up' : 'chevron-right'")
                      n-button.btn-color.d-none.d-lg-block(
                        @click="expandTable",
                        :buttonText="expandTableInfo ?'Hide details' : 'Show all standard policy parameters'",
                        :variant="'light'",
                        :rightImageIcon="expandTableInfo ? 'chevron-up' : 'chevron-down'")
                  .d-none.d-lg-block
                    .row.no-gutters.mt-5
                      .col
                        .text-customize-section.font-weight-medium.font-sm
                          div Need to make changes to this plan?
                          div Click the customize plan button
                      .col.d-lg-flex.flex-row-reverse.pr-5.d-none
                        n-button.btn-customize-section(
                          :disabled="false",
                          @click="showCustomizationModal()",
                          buttonText="Customize Plan",
                          :variant="'outline-'+getPlanClass")
                  .row.no-gutters.mt-5.mx-2.left-add-on-section.position-relative
                    .col-md-12.policy-addons-section(:class="prospectState.addons.length==0?'collapsed':''")
                      .policy-addons-header-container
                        .d-flex.text-white.pt-4.pl-4.ml-2
                          i.icon-add.pr-2
                          span.hb5.font-weight-semibold Policy Addons
                      .pt-4.pl-4.ml-2
                        .policy-addons-sub-header-text
                          span.font-hc.font-weight-semibold.text-gray-700 {{prospectState.addons.length}} Items
                      AddOnCard(
                        :cardsData="addons",
                        :selectedAddons="prospectStateChanges.addons",
                        @submitAddOns="computeSelectedAddOns")
                    .w-100.text-center.policy-addon-btn
                      n-button.mt-2.w-75(
                        :disabled="false",
                        @click="prevStep",
                        :buttonText="'Go back to addons catalog'",
                        :variant="'light'")
        .review-right-section.col-lg-4.col-md-12.d-none.d-lg-block(
          @scroll="onScroll",
          :class="isTop ? 'fix-right-section' : ''")
          ReviewPlanCard(
            :prospectState="prospectStateChanges",
            :planPricing="getPremiumEstimate",
            :addOns="selectedAddOns",
            @confirmPlan="confirmPlan")
    b-modal#review-plan-card.w-100(
      hide-footer,
      hide-header,
      scrollable,
      v-model="modalShow",
      body-class="p-0")
      ReviewPlanCard.d-block.d-lg-none(
        :prospectState="prospectStateChanges",
        :planPricing="getPremiumEstimate",
        :addOns="selectedAddOns",
        @confirmPlan="confirmPlan")
    .final-section-container.d-block.d-lg-none.btm-final-section
      .final-section-root.stick
        div
          .h4.ml-2
            | {{ getPremiumEstimate[0]/prospectState.meta.employee_count | inr }} / Employee
          .font-md.font-weight-medium.text-gray-600.ml-2 Annual Premium Estimate
        div
          n-button.final-continue-btn(
            v-b-modal.review-plan-card,
            :disabled="false",
            @click="() => null",
            buttonText="Continue",
            rightImageIcon="chevron-up")
    policy-customization-modal(
      :id="'customization-modal'",
      :policyData="prospectState",
      :planIndex="planIndex",
      :editableData="editableData",
      :parent="'review-page'",
      @saveChangesToTable="saveChangesToTable")
  n-footer.d-none.d-lg-block
</template>

<script>
import NovaNavbar from "@/layout/NovaNavbar.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import NFooter from "@/layout/Footer.vue";
import AddOnCard from "./components/AddOnCard";
import BillLine from "./components/BillLine.vue";
import ReviewPlanCard from "./components/ReviewPlanCard.vue";
import ProgressBar from "./components/ProgressBar.vue";
import PolicyCustomizationModal from "./components/PolicyCustomizationModal.vue";
import resDefs from "./definitions";

export default {
  name: "ReviewPlan",
  components: {
    NovaNavbar,
    NButton,
    AddOnCard,
    BillLine,
    ReviewPlanCard,
    ProgressBar,
    PolicyCustomizationModal,
    NFooter,
  },
  data() {
    const resName = "prospects";
    const resDef = resDefs[resName];
    return {
      resName,
      resDef,
      addons: [
        {
          id: "134",
          title: "Dental Care",
          name: "add-ons",
          icon: "teeth",
          description: "Access to best in class Dental facilities and coverage for checkups and surgical procedures",
          cost: "499",
        },
        {
          id: "135",
          title: "Personal Accident",
          name: "add-ons",
          icon: "bandage",
          description: "The best-in-class insurance of 10L covering death, temporary, partial or permanent disability",
          cost: "599",
        },
        {
          id: "136",
          title: "Health Checkups",
          name: "add-ons",
          icon: "heart",
          description: "Best preventive health check up packages with expert diagnosis and comprehensive tests",
          cost: "699",
        },
        {
          id: "137",
          title: "OPD - Essentials",
          name: "add-ons",
          icon: "hospital",
          description:
            "Consulatations across the following specialities - Gynecology, Pediatrician, Orthopedic & Derma",
          cost: "799",
        },
      ],
      selectedAddOns: [],
      expandTableInfo: false,
      windowTop: 0,
      modalShow: false,
      prospectState: {},
      prospectStateChanges: null,
      prospectId: "",
      editableData: [],
      planIndex: -1,
      modifiedParameter: {},
    };
  },
  computed: {
    coverageType: function () {
      return utils.getCoverageType("ESCP");
    },
    isTop: function () {
      return this.windowTop > 83;
    },
    selectedPlanFields: function () {
      return this.prospectState.pricing_table_state.fields.filter(
        (field) => field.key === this.prospectState.meta.selectedPolicy || field.key === "feature"
      );
    },
    capitalizePlanName: function () {
      return (
        this.prospectState.meta.selectedPolicy.charAt(0).toUpperCase() + this.prospectState.meta.selectedPolicy.slice(1)
      );
    },
    getPremiumEstimate: function () {
      const prices = [];
      prices[0] =
        this.prospectState.pricing_table_state.items[this.prospectState.pricing_table_state.items.length - 3][
          this.prospectState.meta.selectedPolicy
        ];
      prices[1] = prices[0] * 0.18;
      prices[2] = prices[0] * 1.18;
      return prices;
    },
    getPlanClass: function () {
      const { selectedPolicy } = this.prospectState.meta;
      let className = "";
      if (selectedPolicy === "basic") {
        className = "primary";
      } else if (selectedPolicy === "standard") {
        className = "dark";
      } else if (selectedPolicy === "prime") {
        className = "success";
      }
      return className;
    },
  },
  watch: {
    prospectState() {
      this.prospectStateChanges = JSON.parse(JSON.stringify(this.prospectState));
      this.editableData = this.prospectStateChanges.pricing_table_state.items.filter((item) => {
        return [
          "sum insured",
          "normal room rent limit",
          "maternity benefits - limits and coverages",
          "wait period",
        ].includes(item.feature.trim().toLowerCase());
      });
      this.computeSelectedAddOns(this.addons, "watcher");
    },
  },
  async created() {
    this.prospectId = await utils.fromNanoId(this, this.$route.params.id);
    this.progressBarSteps = [
      {
        name: "About Workplace",
        icon: "city",
        routeName: "workspace",
        idParam: this.$route.params.id,
      },
      {
        name: "About Employees",
        icon: "users",
        routeName: "employees",
        idParam: this.$route.params.id,
      },
      {
        name: "Viewing Plans",
        icon: "claims-1",
        routeName: "pricingTable",
        idParam: this.$route.params.id,
      },
      {
        name: "Policy Add-ons",
        icon: "add",
        routeName: "addons",
        idParam: this.$route.params.id,
      },
      {
        name: "Review Plan",
        icon: "flag",
        routeName: "reviewPlan",
        idParam: this.$route.params.id,
      },
    ];
    this.$apollo.addSmartQuery("prospectState", {
      query: this.resDef.singleQuery,
      variables: () => ({
        id: this.prospectId,
      }),
      update(data) {
        if (!data.node) {
          this.$router.push("/error/not-found");
          this.$Progress.fail();
        }
        this.$Progress.finish();
        return this.resDef.transform(data.node);
      },
    });
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.windowTop = window.top.scrollY;
    },
    openCalendlyPopup() {
      utils.openCalendlyPopup();
    },
    computeSelectedAddOns(selectedAddOns, source = "default") {
      this.selectedAddOns = selectedAddOns.filter((addon) => {
        return this.prospectState.addons.includes(addon.title);
      });
      if (source !== "watcher") this.saveAddons();
    },
    expandTable() {
      this.expandTableInfo = !this.expandTableInfo;
    },
    saveChangesToTable(changes) {
      const items = this.prospectStateChanges.pricing_table_state.items;
      for (let i = 0; i < items.length; i++) {
        const changedItem = changes.editableDataChanges[items[i].feature.replace(/\s/g, "").toLowerCase()];
        if (changedItem) {
          items[i][changes.policy.termPrice] = changedItem;
        }
      }
      this.saveChanges();
    },
    showCustomizationModal() {
      this.planIndex =
        this.prospectState.meta.selectedPolicy === "basic"
          ? 0
          : this.prospectState.meta.selectedPolicy === "standard"
          ? 1
          : 2;
      this.$bvModal.show("customization-modal");
    },
    async saveChanges() {
      this.prospectState = JSON.parse(JSON.stringify(this.prospectStateChanges));
      this.prospectState.pricingTableState = this.prospectState.pricing_table_state;
      this.prospectState.meta.org_name = this.prospectState.organization;
      this.prospectState.meta.coverage_type = this.prospectState.coverageType;
      this.prospectState.meta.employee_count = this.prospectState.employeeCount;
      this.prospectState.meta.employee_avg_age = this.prospectState.employeeAvgAge;
      this.prospectState.meta.location = this.prospectState.location;
      this.prospectState.meta.industry = this.prospectState.industry;
      this.prospectState.calculatePremiums = true;

      this.$store.commit("clearToasts");
      try {
        await this.$apollo.mutate({
          mutation: this.resDef.upsertMutation,
          variables: this.resDef.transform(this.prospectState),
        });
      } catch (err) {
        console.log(err);
      }
    },
    async saveAddons() {
      this.prospectState.addons = JSON.parse(JSON.stringify(this.prospectStateChanges.addons));
      this.prospectState.pricingTableState = this.prospectState.pricing_table_state;
      this.$store.commit("clearToasts");
      try {
        await this.$apollo.mutate({
          mutation: this.resDef.upsertMutation,
          variables: this.resDef.transform(this.prospectState),
        });
      } catch (err) {
        console.log(err);
      }
    },
    async confirmPlan() {
      try {
        this.prospectState.meta.submitted = true;
        const { items } = this.prospectState.pricing_table_state;
        const premiumPerEmployee = this.getPremiumEstimate[0] / this.prospectState.meta.employee_count;
        const selectedPolicy = this.prospectState.meta.selectedPolicy;
        const selectedPolicyName = this.prospectState.pricing_table_state.policies.filter((policy) => {
          return policy.termPrice === selectedPolicy;
        })[0].cardTitle;
        this.editableData.forEach((field) => {
          this.modifiedParameter[field.feature.replace(/\s/g, "").toLowerCase()] = field[selectedPolicy];
        });
        await this.$apollo.mutate({
          mutation: this.resDef.eventMutation,
          variables: {
            id: null,
            prospectId: this.prospectId,
            eventName: "submit_rfq",
            eventParams: {
              planName: selectedPolicyName,
              pricingTableState: utils.filterPlan(items, selectedPolicy),
              meta: this.prospectState.meta,
              modifiedParameter: this.modifiedParameter,
              premiumPerEmployee,
              addons: this.prospectState.addons,
            },
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    prevStep() {
      this.$router.push(`/policy-calculator/addons/${this.$route.params.id}`);
    },
    goToPoliciesPage() {
      this.$router.push(`/policy-calculator/pricing-table/${this.$route.params.id}`);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.main-panel {
  background: none;
  background-color: $website-bg;
  & > .footer {
    background-color: $website-bg;
  }
}

.tiber-bg {
  background: $tiber;
  max-width: 100vw !important;
}
.header-tiber-bg {
  background: linear-gradient(180deg, $tiber 50vh, transparent 50vh);
  max-width: 100vw !important;
  min-height: 100vh !important;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.user-progress {
  position: relative;
  height: 50px;
  background: transparent;
  margin: 15px 0;
  text-align: center;
}

.main-content > .card-body {
  padding: 0;
}

.main-content {
  width: 100%;
}

.review-container {
  display: flex;
}

.review-left-section {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  color: $gray-700;
  width: 100% !important;
  font-size: 0.875rem;
  @include media-breakpoint-down(lg) {
    max-width: 540px !important;
    margin: 0 auto;
  }
}

.review-plan-header {
  background: linear-gradient(180deg, #fbfdff 0%, #f8f8f8 100%);
  border-bottom: 2px solid $gray-300;
  .header-text {
    font-style: normal;
    color: $gray-900;
  }
}

.plan-info-row {
  margin-top: 14px;
  color: $white;
  & .bg-success {
    background-color: $teal-800 !important;
  }

  .sub-amount-text {
    opacity: 0.75;
  }

  .border-rad {
    border-radius: 0.5rem 0.5rem 0px 0px;
  }
  .btn-basic-plan {
    background: $blue-400;
    &:active {
      background: $blue-400 !important;
    }
  }
  .btn-standard-plan {
    background: $gray-800;
    &:active {
      background: $gray-800 !important;
    }
  }
  .btn-prime-plan {
    background: $teal-700;
    &:active {
      background: $teal-700 !important;
    }
  }
}
.params-title {
  border: 2px solid $gray-200;
  color: $gray-700;
  margin: 0 0.5rem;
  padding: 1rem 1.5rem 0 1.5rem;
}
.table-container {
  padding: 5px 0px;
  overflow: hidden;
}

.expandTable {
  height: auto;
  overflow: visible;
}
.collapseTable {
  height: 27rem;
  overflow: hidden;
}

.plan-table > * > tr {
  & > td {
    color: $gray-900;
    width: 220px;
    vertical-align: middle !important;
    background: $white !important;
  }
  & > th {
    color: $gray-700;
    vertical-align: middle !important;
    font-size: 1rem;
    line-height: 19px;
    background: $white !important;
    padding-left: 0;
  }

  &:first-child > th,
  &:first-child > td {
    border-top: 0;
    background: $white !important;
  }
}

.btn-color {
  position: relative;
  bottom: -19px;
  background: $gray-200;
  border-radius: 6px;
  width: 100%;
}

.text-customize-section {
  color: $gray-800;
  margin-left: 2.3rem;
}
.continue-btn {
  width: 100%;
  margin: 12px 0;
  background: $gray-900;
  color: $white;
}

.policy-addons-section {
  border: 2px solid $gray-200;
  overflow: visible;
  border-radius: 0px 0px 6px 6px;
  padding-bottom: 3rem;
  .policy-addons-header-container {
    background: $blackpearl;
    border-radius: 6px 6px 0px 0px;
    padding: 0 0 20px 0;
    position: relative;
    left: -1px;
    top: -1px;
  }
}

.review-right-section {
  position: absolute;
  width: 100%;
  color: $gray-700;
  font-size: 0.875rem;
  background: $white;
  border: 3.5px solid $teal-900;
  box-sizing: border-box;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  left: calc(100vw / 2 + 740px / 4 - 50px);
  max-width: 520px;
}

.fix-right-section {
  position: fixed;
  top: 75px;
}

.btm-final-section {
  position: fixed;
  bottom: 0;
  background: $gray-900;
}

.final-continue-btn {
  color: $white;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  &:active,
  &:focus {
    background: rgba(255, 255, 255, 0.25) !important;
  }
}

.final-section-container {
  width: 100%;
  background: $tiber;
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.04), 0px -2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0px;
  padding: 1rem;
  color: $white;
  z-index: 1010;
}

.final-section-root {
  display: flex;
  justify-content: space-between;
}

.stick {
  position: sticky;
  bottom: 0;
}
.text-gray-600 {
  color: $gray-600;
}

@media (max-width: 1440px) {
  .review-right-section {
    left: calc(100vw / 2 + 740px / 4 - 50px);
    max-width: 390px;
  }

  .white-btn {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .review-right-section {
    left: calc(100vw / 2 + 620px / 4 - 50px);
    max-width: 350px;
  }
}

@media (max-width: 991px) {
  .review-right-section {
    position: relative;
    left: 0;
    max-width: none;
  }
  .modal .modal-sheet {
    min-width: 100%;
    bottom: 0;
    position: fixed;
    margin: 0;
    max-height: 90%;
  }

  .modal.fade .modal-dialog {
    transform: translate3d(0, 100%, 0);
  }
  .modal.show .modal-dialog {
    transform: translate3d(0, calc(100vh - 104.5%), 0);
  }
}

@media (max-width: 992px) {
  .review-plan-header {
    border-bottom: none;
    background: $white;
  }
  .header-text {
    padding: 21px 21px 21px 10px;
  }

  .params-title {
    border-radius: 0 0 9px 9px;
  }
  .border-nova-basic {
    border: 2px solid $blue-500;
  }
  .border-nova-standard {
    border: 2px solid $gray-900;
  }
  .border-nova-prime {
    border: 2px solid $teal-800;
  }
  .policy-addons-section {
    border: 2px solid $blackpearl;
    border-radius: 9px;
    height: auto;
    position: relative;
    top: -2px;
    width: 100%;
    &.collapsed {
      height: 9rem;
    }
    .policy-addons-header-container {
      background: $blackpearl;
      width: calc(100% + 2px);
    }
  }
  .continue-btn {
    margin: 12px;
  }

  .modal.show .modal-dialog {
    transform: translate3d(0, calc(100vh - 104.5%), 0);
  }
}

@media (max-width: 576px) {
  .modal.show .modal-dialog {
    margin: 0;
    transform: translate3d(0, calc(100vh - 100%), 0);
  }
}

@media (max-width: 500px) {
  .plan-table > * > tr {
    & > td {
      width: 105px;
    }
  }
  .final-amount-text {
    font-size: 14px;
    padding-top: 5px;
  }
  .final-sub-amount-text {
    font-size: 12px;
  }
}

.left-add-on-section {
  margin-bottom: 6rem;
}
@media (min-width: 992px) {
  .left-add-on-section {
    margin-bottom: 0;
  }
}
.policy-addon-btn {
  position: relative;
  top: -2rem;
}
// @media (max-width: 1440px) {
//   .left-add-on-section {
//     height: 25rem;
//     overflow: hidden;
//   }
// }
// @media (max-width: 1200px) {
//   .left-add-on-section {
//     height: 25.5rem;
//     overflow: hidden;
//   }
// }
// @media (max-width: 992px) {
//   .left-add-on-section {
//   height: 24rem;
//   overflow: hidden;
// }
// }
.plan-table > * > * > th {
  padding: 1.5rem;
}
.bg-card {
  border-radius: 0 !important;
  & > .card-body {
    border-radius: 0;
  }
}
@media (min-width: 992px) {
  .bg-card {
    border-radius: 0.25rem !important;
  }
}
</style>
