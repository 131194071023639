import Vue from "vue";
import Vuex from "vuex";
import { v4 as uuid } from "uuid";

Vue.use(Vuex);
export const store = new Vuex.Store({
  state: {
    toasts: [],
    toastsCountMap: {},
    prospectId: "",
    scrollPositions: {},
    showAnnouncement: true,
    showVersioningModal: false,
  },
  getters: {
    isLoggedIn() {
      return false;
    },
    getToastsCount(state) {
      return (message) => state.toastsCountMap[message]?.count || 0;
    },
    isAnnouncementShown(state) {
      return state.showAnnouncement;
    },
    showVersioningModal(state) {
      return state.showVersioningModal;
    },
  },
  actions: {
    async loadLocalStore(ctx) {
      const prospectId = localStorage.getItem("prospectId");
      if (prospectId) {
        ctx.state.prospectId = prospectId;
        return true;
      }
      return false;
    },
  },
  mutations: {
    saveProspectId(state, prospectId) {
      state.prospectId = prospectId;
      localStorage.setItem("prospectId", prospectId);
    },
    addToast(state, { message, variant, id }) {
      if (!id) id = uuid();
      const oldToast = state.toasts.find((toast) => toast.message === message);
      if (oldToast) Object.assign(oldToast, { variant, message });
      else state.toasts.push({ variant, message, id });
      if (!state.toastsCountMap[message]) {
        Vue.set(state.toastsCountMap, message, { count: 0 });
      }
      state.toastsCountMap[message].count++;
      return id;
    },
    clearToast(state, id) {
      const idx = state.toasts.findIndex((toast) => toast.id === id);
      state.toastsCountMap[state.toasts[idx].message].count = 0;
      state.toasts.splice(idx, 1);
    },
    clearToasts(state) {
      state.toasts = [];
      state.toastsCountMap = {};
    },
    changeScrollPosition(state, { page, scrollPosition }) {
      Vue.set(state.scrollPositions, page, scrollPosition);
    },
    closeAnnouncement(state) {
      state.showAnnouncement = false;
    },
    enableVersioningModal(state, flag) {
      state.showVersioningModal = flag || false;
    },
  },
});
