import { render, staticRenderFns } from "./ReviewPlanCard.vue?vue&type=template&id=cb8abab0&scoped=true&lang=pug"
import script from "./ReviewPlanCard.vue?vue&type=script&lang=js"
export * from "./ReviewPlanCard.vue?vue&type=script&lang=js"
import style0 from "./ReviewPlanCard.vue?vue&type=style&index=0&id=cb8abab0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb8abab0",
  null
  
)

export default component.exports