import "core-js";
import "regenerator-runtime/runtime";
import Vue from "vue";
import Vuex from "vuex";
import VueApollo from "vue-apollo";
import VueProgressBar from "vue-progressbar";
import VueGtag from "vue-gtag";

import App from "./App";
import { store } from "./store";
import router from "./router/index";
import { apolloClient } from "./apollo";
import NovaTheme from "@/plugins/novaTheme";
import "../registerServiceWorker";

const vueProgressBarOptions = {
  color: "#78f440",
  failedColor: "#874b4b",
  thickness: "4px",
};

Vue.use(VueProgressBar, vueProgressBarOptions);
Vue.use(Vuex);
Vue.use(NovaTheme);
if (process.env.VUE_APP_WEB_GA_ID) Vue.use(VueGtag, { config: { id: process.env.VUE_APP_WEB_GA_ID } }, router);
Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: "loading",
  },
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
