<template lang="pug">
.shadow.rounded-lg.bg-white.mx-auto
  .row.no-gutters
    .col-lg-7.col-md-12
      .getintouch-form-container
        span.getintouch-title.font-hc GET IN TOUCH
        b-form.py-4(@submit.prevent="submitForm")
          vue-form-generator(
            :schema="resDef.schema",
            :model="resModel",
            :options="resOptions",
            ref="formData")
          n-button(
            variant="dark",
            size="lg",
            block,
            buttonText="Submit",
            type="submit",
            @click="$refs.formData.validate()",
            @keydown.enter="$refs.formData.validate()")
    .col-lg-5.d-none.d-lg-block.getintouch-testimonial.rounded.rounded-left-none
      b-carousel#testimonialCarousel.testimonial-carousel-container(v-model="slide", :interval="5000")

        .carousel-item(v-for="testimonial in testimonials", :key="testimonial.id")
          .testimonial-carousel-card.rounded.shadow-sm.mb-5.mx-2.px-4.pt-4.pb-2
            p.text-gray-900.font-sm.font-weight-medium(v-html="testimonial.text")
            AvatarCard(
              :imgSrc="`${testimonial.personImage.url}`",
              :name="testimonial.name",
              :role="testimonial.designation",
              :company="testimonial.org")

        n-info-modal(:existingUser="existingUser")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NInfoModal from "@/components/NovaInfoModal.vue";
import AvatarCard from "../components/AvatarCard.vue";
import resDefs from "../../policyCalculator/definitions";
import formDefs from "../../forms/definitions";
import { isVfgErrorPresent } from "@/utils";

export default {
  name: "GetInTouch",
  components: {
    NButton,
    AvatarCard,
    NInfoModal,
  },
  props: {
    heroPhone: {
      type: String,
      default: "",
    },
  },
  data() {
    const resName = "renewalForm";
    return {
      resName,
      resDef: resDefs[resName],
      resOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      resModel: {
        phone: this.heroPhone,
      },
      slide: 0,
      existingUser: false,
      testimonials: [],
    };
  },
  async mounted() {
    const result = await this.$apollo.query({
      query: resDefs.strapiContents.listQuery,
      variables: {
        filter: {
          collectionType: "Testimonial",
          tags: ["GetInTouch"],
        },
      },
    });
    this.testimonials = result?.data?.strapiContents?.edges.map((t) => t.node?.meta);
  },
  methods: {
    async submitForm() {
      if (isVfgErrorPresent(this.$refs.formData.errors)) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please enter valid details",
        });
      }
      try {
        const { name, email, phone, employeeCount, role } = this.resModel;
        const result = await this.$apollo.mutate({
          mutation: formDefs.starterForm.createFormResponse,
          variables: {
            meta: {
              data: { name, email, phone, employeeCount, role },
              landing: "GetInTouchForm",
            },
            formSlug: "website-leads",
          },
        });
        if (result) {
          if (result.data.upsertFormResponse.formResponse.meta.existingUser) {
            this.existingUser = true;
          } else {
            this.existingUser = false;
          }
          this.$bvModal.show("info-modal");
          this.resModel = {};
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.getintouch-form-container {
  margin: 3.7rem 4.5rem;
  @include media-breakpoint-down(md) {
    margin: 2rem 1.5rem 0 1.5rem;
  }
}

.getintouch-title {
  font-weight: 700;
  letter-spacing: 0.04em;
  color: $purple-700;
  @include media-breakpoint-down(sm) {
    color: $gray-600;
  }
}

.getintouch-testimonial {
  background-image: url("~@/assets/images/T01.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-carousel-container {
  top: 40%;
  padding: 0 2rem;
}

.testimonial-carousel-card {
  background: $white;
  box-sizing: border-box;
  border-top: 0.25rem solid $purple-900;
}
</style>
