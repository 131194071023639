<template lang="pug">
div
  b-modal(:id="id", centered, hide-header, hide-footer, tall)
    b-form(@submit.prevent="updateForm")
      vue-form-generator.p-4(
        :schema="resDef.schema",
        :model="resModel",
        :options="resOptions",
        ref="formData")
      .mb-2.font-sm.text-gray-700.font-weight-medium.text-center
        | By continuing to sign in, you agree to&nbsp;
        abbr(v-b-tooltip.hover, title="Novabenefits Private Limited") Nova's&nbsp;
        b-link.pr-1.font-sm(:href="termsLink", target="_blank") Terms of Use
        | and
        b-link.pl-1.font-sm(:href="privacyLink", target="_blank") Privacy Policy
      .px-4.mt-2
        n-button(
          variant="dark",
          block,
          buttonText="Continue",
          rightImageIcon="chevron-right",
          type="submit",
          @click="$refs.formData.validate()",
          @keydown.enter="$refs.formData.validate()")
  n-info-modal()
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NInfoModal from "@/components/NovaInfoModal.vue";
import gql from "graphql-tag";
import resDefs from "../definitions";
import { isVfgErrorPresent } from "@/utils";

export default {
  components: {
    NButton,
    NInfoModal,
  },
  props: {
    id: {
      type: String,
      default: "renewal-form-modal",
    },
  },
  data() {
    const resName = "renewalForm";
    return {
      resName,
      resModel: {},
      resDef: resDefs[resName],
      resOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  computed: {
    termsLink() {
      return process.env.VUE_APP_TERMS_URL;
    },
    privacyLink() {
      return process.env.VUE_APP_PRIVACY_URL;
    },
  },
  methods: {
    async updateForm() {
      if (isVfgErrorPresent(this.$refs.formData.errors)) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please enter valid details before submitting",
        });
      } else {
        this.$store.commit("clearToasts");
        try {
          const result = await this.$apollo.mutate({
            mutation: gql`
              mutation CreateFormResp($meta: JSONObject!, $formId: ID, $formSlug: String) {
                upsertFormResponse(input: { meta: $meta, formId: $formId, formSlug: $formSlug }) {
                  formResponse {
                    id
                    __typename
                  }
                  __typename
                }
              }
            `,
            variables: {
              meta: {
                ...this.resModel,
                landing: "PremiumEstimateRenewalPage",
              },
              formSlug: "website-leads",
            },
          });
          if (result) {
            this.$bvModal.hide(this.id);
            this.$bvModal.show("info-modal");
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    confirmRemoval() {
      this.$emit("confirmRemoval", this.title);
      this.$bvModal.hide(this.id);
    },
  },
};
</script>
